<template>
  <div>
    <div class="loading min-h-300" v-if="loading">
      <LoadingAnim />
    </div>
    <ChatContents v-else :messages="messages" />
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import ChatContents from '@/components/chat/ChatContents.vue';

export default {
  name: 'FlowDetailReport',
  components: {
    LoadingAnim,
    ChatContents,
  },
  data() {
    return {
      loading: true,
      messages: {},
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('fetchFlowStream', this.$route.params.id)
        .then((response) => {
          this.messages = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
