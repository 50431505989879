<template>
  <div class="chat-contents">
    <div class="chat-content-wrap">
      <div class="chat-wrap-inner scroll-bottom" data-scroll="minimal-dark">
        <div class="chats">
          <div
            v-for="(message, n) in messages"
            :key="n"
            class="chat"
            :class="{
              'chat-right': message.origin === 'CLIENTE',
              'chat-left': message.origin === 'FLOW',
            }"
          >
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <div
                    class="live-message-attachments"
                    v-if="message.url"
                  >
                    <video
                      v-if="checkMediaType(message.url) == 'video'"
                      :src="message.url"
                      controls="true"
                    ></video>
                    <audio
                      v-else-if="checkMediaType(message.url) === 'audio'"
                      :src="message.url"
                      :type="`audio/${getExtension(message.url)}`"
                      controls
                      preload="metadata"
                    ></audio>
                    <img
                      :src="message.url"
                      v-else-if="checkMediaType(message.url) === 'image'"
                    />
                    <template v-else>
                      <a
                        :href="message.url"
                        target="_blank"
                        class="live-external"
                      >
                        <i class="fas fa-file"></i>
                        <!-- <span class="live-attach-name">{{ file.name }}</span>-->
                        <div class="live-attach-type">
                          {{ getExtension(message.url) }}
                        </div>
                      </a>
                    </template>
                  </div>
                  <p>{{ message.message }}</p>
                  <span class="chat-time">{{
                      message.creationDate | date('DD/MM/Y H:m')
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatContents',
  components: {},
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getExtension(url) {
      return url.split('.').pop().toLowerCase();
    },
    checkMediaType(url) {
      const extension = this.getExtension(url);
      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image';
        case 'mp3':
        case 'ogg':
        case 'oga':
          return 'audio';
        case 'mp4':
        case 'webm':
          return 'video';
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-contents {
  height: 100%;

  .chat-content-wrap {
    height: 100%;
    position: relative;
    width: 100%;

    .chat-wrap-inner {
      overflow: auto;

      .chats {
        padding: 30px 15px;

        .chat {
          .chat-body {
            display: block;
            margin: 10px 0 0;
            margin-top: 10px;
            overflow: hidden;
          }

          &.chat-right {
            .chat-content {
              position: relative;
              display: block;
              float: right;
              padding: 8px 15px;
              margin: 0;
              clear: both;
              background: #8840fc;
              color: #fff;
              border-radius: 10px 2px 10px 10px;
              max-width: 366px;

              p {
                color: #fff !important;
              }
            }
          }

          &.chat-left {
            .chat-content {
              background-color: #fff;
              border: 1px solid rgba(211, 220, 233, 0.6);
              background: #f0f6ff;
              float: left;
              position: relative;
              padding: 8px 15px;
              border-radius: 2px 10px 10px 10px;
              max-width: 366px;
            }
          }
        }

        .chat-bubble {
          display: block;
          width: 100%;
          float: left;
          margin-bottom: 10px;
          font-size: 0.8125rem;
          video {
            width: 100%
          }
        }
      }
    }
  }
}
.live-external {
  position: relative;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  & > i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0, 0, 0, 0.15);
    padding: 3px 6px;
    clear: both;
  }
}
</style>
